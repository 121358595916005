<template>
	<div>
		<section class="section is-main-section">
			<card-component
				:title="
					$route.params.id
						? `${form.name} Düzenle`
						: 'Banka Hesabı Oluştur'
				"
				icon="domain">
				<form @submit.prevent="save">
					<b-field grouped group-multiline>
						<b-field label="Banka" expanded>
							<b-select
								size="is-small"
								v-model="form.bankId"
								expanded
								required>
								<option :value="null">Seçim Yapınız</option>
								<option
									:value="bank.uuid"
									v-for="bank in banks"
									:key="bank.uuid">
									{{ bank.name }}
								</option>
							</b-select>
						</b-field>
						<!--
            <b-field label="Banka" expanded v-if="form.method === 'BANK_TRANSFER' || form.method === 'FOREX'">
              <b-select size="is-small" v-model="form.bankName" expanded required>
                <option :value="null">Seçim Yapınız</option>
                <option :value="bank.uuid" v-for="bank in bankLists" :key="bank.uuid">{{ bank.name }}</option>
              </b-select>
            </b-field>
          -->
						<b-field label="Hesap Adı" expanded v-if="!$route.params.id">
							<b-input
								size="is-small"
								v-model="form.name"
								icon="domain"
								placeholder="Hesap Adı"
								name="name"
								required />
						</b-field>
						<b-field label="IBAN" expanded v-if="!$route.params.id">
							<b-input
								size="is-small"
								v-model="form.iban"
								icon="domain"
								placeholder="IBAN"
								name="iban"
								required
								@keyup.native="ibanValid" />
							<img
								v-if="
									bankLogo &&
									(form.method === 'BANK_TRANSFER' ||
										form.method === 'FOREX')
								"
								:src="bankLogo"
								width="100" />
						</b-field>
					</b-field>
					<hr />

					<b-field group-multiline grouped>
						<b-field label="Minimum Yatırım Limiti" expanded>
							<b-input
								size="is-small"
								v-model="form.minimumLimit"
								icon="currency-usd"
								placeholder="Minimum Limit"
								name="minimumLimit"
								required />
						</b-field>
						<b-field label="Maksimum Yatırım Limiti" expanded>
							<b-input
								size="is-small"
								v-model="form.maximumLimit"
								icon="currency-usd"
								placeholder="Maksimum Limit"
								name="maximumLimit"
								required />
						</b-field>
						<b-field
							label="Grup"
							expanded
							v-if="!$store.state.user.siteId">
							<b-input
								size="is-small"
								v-model="form.group"
								icon="domain"
								placeholder="Grup"
								name="group"
								required />
						</b-field>
						<b-field
							label="Auto Bank"
							v-if="
								form.method === 'BANK_TRANSFER' && theme === '0'
							">
							<b-checkbox
								size="is-small"
								v-model="form.isAuto"
								icon="domain"
								name="isAuto" />
						</b-field>
						<b-field
							label="FAST"
							v-if="
								form.method === 'BANK_TRANSFER' && theme === '0'
							">
							<b-checkbox
								size="is-small"
								v-model="form.fast"
								icon="domain"
								name="isAuto" />
						</b-field>
					</b-field>
					<hr />
					<b-field expanded label="Gösterilecek Siteler">
						<b-select
							multiple
							size="is-small"
							v-model="form.sites"
							expanded>
							<option
								:value="item.uuid"
								v-for="item in sites"
								:key="item.uuid">
								{{ item.name }}
							</option>
						</b-select>
					</b-field>
					<hr />
					<b-field group-multiline grouped>
            <b-field label="Durum" v-if="$route.params.id">
              <b-select v-model="form.status" size="is-small">
                <option value="ENABLED">Aktif</option>
                <option value="DISABLED">Pasif</option>
                <option value="BLOCKED">Bloke</option>
                <!--<option value="RESERVED">Yedek</option>-->
              </b-select>
            </b-field>
						<b-field label="Sıra">
							<b-numberinput
								size="is-small"
								v-model="form.sequence"
								icon="domain"
								placeholder="Sıra"
								name="sequence"
								min="0"
								required />
						</b-field>
					</b-field>
					<hr />
					<div class="is-flex is-justify-content-end is-flex-grow-1">
						<div class="control mr-2">
							<b-button
								native-type="submit"
								type="is-primary"
								size="is-small">
								{{ $route.params.id ? "Güncelle" : "Ekle" }}
							</b-button>
						</div>
						<div class="control">
							<b-button
								type="is-primary is-outlined"
								size="is-small"
								@click="$router.go(-1)"
								>Geri Dön</b-button
							>
						</div>
					</div>
				</form>
			</card-component>
		</section>
	</div>
</template>

<script>
	import mapValues from "lodash/mapValues"
	import CardComponent from "@/components/CardComponent"
	import {
		BankAccountService as Service,
		BankService,
		BankListService,
		SiteService,
	} from "@/services"

	export default {
		name: "BankCreateOrUpdate",
		components: { CardComponent },
		data() {
			return {
				isLoading: false,
				form: {
					name: null,
					bankId: null,
					iban: null,
					type: "STANDARD",
					showType: "PRIVATE",
					method:
						this.$store.state.user.group === "FOREX"
							? "FOREX"
							: "BANK_TRANSFER",
					isMailService: true,
					email: null,
					password: null,
					defaultEmail: null,
					dailyLimit: 0,
					dailyAmountLimit: 0,
					minimumLimit: null,
					maximumLimit: null,
					status: "DISABLED",
					isDefault: false,
					isAuto: false,
					fast: false,
					sites: [],
					users: [],
					sequence: 0,
					integrationSettings: {
						CLIENT_CODE: null,
						CLIENT_USERNAME: null,
						CLIENT_PASSWORD: null,
						GUID: null,
					},
					group: null,
					toMatch: null,
				},
				banks: [],
				sites: [],
				bankLists: [],
				tmp: {
					siteId: null,
					userId: null,
				},
				bankLogo: null,
			}
		},
		mounted() {
			if (this.$route.params.id) this.getData()
			this.getSites()
			this.getBanks()
			this.getBankLists()
		},
		methods: {
			ibanValid() {
				if (this.$route.params.id) {
				}

				if (this.form.iban.length >= 9) {
					const bankCode = this.form.iban.slice(6, 9)
					const bank = this.bankLists.find(
						(item) => item.code === bankCode
					)
					if (bank) {
						this.form.bankName = bank.uuid
						this.bankLogo = bank.logo
					} else {
						this.bankLogo = null
						console.log("banka yok")
					}
				} else {
					this.bankLogo = null
				}
			},
			getBanks() {
				BankService.all({ type: "BANK_TRANSFER", status: 1 }).then(
					(res) => (this.banks = res.data)
				)
			},
			getSites() {
				SiteService.all({ status: 1 }).then(
					(res) => (this.sites = res.data)
				)
			},
			getBankLists() {
				BankListService.all().then((res) => (this.bankLists = res.data))
			},
			getData() {
				Service.edit(this.$route.params.id).then((res) => {
					let sites = []
					res.data.sites.map((item) => sites.push(item.siteId))
					if (!res.data.integrationSettings)
						res.data.integrationSettings =
							this.form.integrationSettings
					this.form = { ...res.data, sites }
				})
			},
			save() {
				if (
					this.form.method === "BANK_TRANSFER" ||
					this.form.method === "FOREX"
				) {
					if (!this.validateTRIBAN(this.form.iban)) {
						this.$buefy.toast.open({
							message: "Geçersiz IBAN!",
							type: "is-danger",
						})
						return
					}
				}

				Service[this.$route.params.id ? "update" : "create"]({
					...this.form,
				})
					.then(() => {
						this.$buefy.toast.open({
							message: this.$route.params.id
								? "Güncellendi"
								: "Eklendi",
							type: "is-success",
						})
						this.reset()
						this.$router.go(-1)
					})
					.catch((err) =>
						this.$buefy.toast.open({
							message: err.message,
							position: "is-top-right",
							type: "is-danger",
						})
					)
			},
			reset() {
				this.form = mapValues(this.form, (item) => {
					if (item && typeof item === "object") {
						return []
					}
					return null
				})
			},
		},
	}
</script>
